import UserTopNav from "../../../components/UserTopNav.vue";
import JwtServices from "../../../services/jwt.services";
import moment from "moment";

export default {
	components: { UserTopNav },
	data: () => ({
		loading_overlay: false,
		user: null,
		network_operator_id: null,
		status_items: ["open", "canceled", "closed"],
		selected_status: "",
		loading_overlay: false,
		loading: false,
		pagination: {
			page: 1,
			rowsPerPage: 10,
			totalItems: 0,
			rowsPerPageItems: [10, 50, 100, 250, 500],
		},
		headers: [
			{
				text: "Ticket number",
				align: "start",
				sortable: false,
				value: "ticket_number",
			},
			{ text: "Summary", value: "summary", sortable: false },
			{ text: "Status", value: "status", sortable: false },
			{ text: "Severity", value: "severity", sortable: true },
			{ text: "Affected nodes", value: "affected_node", sortable: false },
			{
				text: "Affected segment",
				value: "affected_segment",
				sortable: false,
			},
			{
				text: "Target time",
				value: "target_to_resolved",
				sortable: false,
			},
			{
				text: "Current process",
				value: "current_process",
				sortable: false,
			},
			{ text: "Start Time", value: "start_time", sortable: false },
			{ text: "End Time", value: "end_time", sortable: false },
			{ text: "Description", value: "description", sortable: false },
		],
		data_alarm: [],
	}),
	filters: {
		moment: function(date) {
			return moment(date).format("DD/MM/YYYY - HH:mm:ss");
		},
	},
	mounted() {
		this.user = JwtServices.getUser();
		this.network_operator_id = this.user.network_operator.id;
		this.initialize();
	},
	methods: {
		initialize() {
			const payload = {
				limit: 10,
				page: 1,
				sort: "asc",
				operator_id: this.network_operator_id,
				status: "",
			};
			this.getHistoryAlarm(payload);
		},
		timeFormat(value) {
			return moment.unix(value).format("DD-MM-YYYY - hh:mm");
		},

		getAlarmByTableRow() {
			this.loading_overlay = true;
			const payload = {
				limit: this.pagination.rowsPerPage,
				page: this.pagination.page,
				sort: "asc",
				operator_id: this.network_operator_id,
				status: this.selected_status,
			};

			const url = new URL(
				process.env.VUE_APP_API_URL + `api/v1/id/fo-alarm/fo/alarm`
			);
			for (let key in payload) {
				url.searchParams.append(key, payload[key]);
			}
			console.log(payload, "payload");
			fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ` + this.user.token,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					this.loading_overlay = false;
					this.data_alarm = [];
					console.log(result, "result tab");
					// Check error
					if (result.error) {
						this.data_alarm = [];
						this.pagination.page = 1;
						this.pagination.totalItems = 1;
						return;
					} else {
						this.data_alarm = result.data.data;
						this.pagination.page = 1;
						this.pagination.totalItems =
							result.data.pagination.total_page;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		getAlarm() {
			this.loading_overlay = true;
			const payload = {
				limit: this.pagination.rowsPerPage,
				page: this.pagination.page,
				sort: "asc",
				operator_id: this.network_operator_id,
				status: this.selected_status,
			};

			this.getHistoryAlarm(payload);
		},
		getHistoryAlarm(payload) {
			this.loading_overlay = true;

			const url = new URL(
				process.env.VUE_APP_API_URL + `api/v1/id/fo-alarm/fo/alarm`
			);
			for (let key in payload) {
				url.searchParams.append(key, payload[key]);
			}
			console.log(payload, "payload");
			fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ` + this.user.token,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					this.loading_overlay = false;
					// console.log(result, "result tab");
					// Check error
					this.data_alarm = [];
					if (result.error) {
						this.data_alarm = [];
						this.pagination.page = 1;
						this.pagination.totalItems = 1;
						return;
					}

					this.data_alarm = result.data.data;
					this.pagination.page = result.data.pagination.current_page;
					this.pagination.totalItems =
						result.data.pagination.total_page;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		resetFilter() {
			this.selected_status = "";
			this.pagination = {
				page: 1,
				rowsPerPage: 10,
				totalItems: 0,
				rowsPerPageItems: [10, 50, 100, 250, 500],
			};
			this.initialize();
		},
	},
};
